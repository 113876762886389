@charset "UTF-8";
/*
Theme Name: Beaver Builder Child Theme - Avoca Web Design
Theme URI: http://www.wpbeaverbuilder.com
Version: 1.0
Description: A starting point for custom development, utilises SASS and other Avoca Web Design developement tools.
Author: Avoca Web Design
Author URI: http://avoca.design
template: bb-theme
*/
/*
* 	Main Icon mixin
*
* 	Based on http://jaydenseric.com/blog/fun-with-sass-and-font-icons 
*
*	e.g. 
*	@include icon('phone', 'before', true, $large, $top:-3px, $margin:5px);
* 	@include icon('phone', 'before', true, $large, $top:-3px, $margin:5px) {
*		color: black;
* 	}
*/
/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.cf:after {
  clear: both; }

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
  *zoom: 1; }

/* ## Float Clearing
--------------------------------------------- */
.author-box:before,
.clearfix:before,
.entry:before,
.entry-content:before,
.footer-widgets:before,
.nav-primary:before,
.nav-secondary:before,
.pagination:before,
.site-container:before,
.site-footer:before,
.site-header:before,
.site-inner:before,
.wrap:before {
  content: " ";
  display: table; }

.author-box:after,
.clearfix:after,
.entry:after,
.entry-content:after,
.footer-widgets:after,
.nav-primary:after,
.nav-secondary:after,
.pagination:after,
.site-container:after,
.site-footer:after,
.site-header:after,
.site-inner:after,
.wrap:after {
  clear: both;
  content: " ";
  display: table; }

@font-face {
  font-family: "entypo";
  src: url("fonts/entypo/entypo-plus.eot");
  src: url("fonts/entypo/entypo-plus.eot?#iefix") format("embedded-opentype"), url("fonts/entypo/entypo-plus.woff") format("woff"), url("fonts/entypo/entypo-plus.ttf") format("truetype"), url("fonts/entypo/entypo-plus.svg#entypo") format("svg");
  font-weight: normal;
  font-style: normal;
  speak: none; }

/*
 * Social Network Colours
 *
 * http://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
 */
/*
 * Brand Colours
 */
/*
* Backgrounds
*/
.bg-light {
  background: #A8A9A8; }

.bg-medium {
  background: #9E9F9E; }

.bg-dark {
  background: #5E5E5E; }

/*
 * Buttons
 */
.button.primary {
  background: #5E5E5E;
  color: #fff; }

.button.secondary {
  background: #A8A9A8;
  color: #5E5E5E; }

.button.outline {
  border: 1px solid #5E5E5E;
  color: #5E5E5E; }

.gform_wrapper input[type=email],
.gform_wrapper input[type=number],
.gform_wrapper input[type=password],
.gform_wrapper input[type=tel],
.gform_wrapper input[type=text],
.gform_wrapper input[type=url] {
  padding: 5px 8px !important;
  border-radius: 0 !important; }

/**
 * Icon Fonts
 */
.phone-number {
  margin-bottom: 0.80901rem; }
  .phone-number:before {
    content: "";
    position: relative;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    speak: none;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: "entypo";
    font-size: 16px;
    font-size: 1.3em;
    margin-right: 6px;
    position: relative;
    top: 1px;
    margin-left: 2px; }

.mobile-number {
  margin-bottom: 0.80901rem; }
  .mobile-number:before {
    content: "";
    position: relative;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    speak: none;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: "entypo";
    font-size: 16px;
    font-size: 1.3em;
    margin-right: 10px;
    position: relative;
    top: 1px;
    margin-left: 3px; }

.email {
  margin-bottom: 0.80901rem; }
  .email:before {
    content: "";
    position: relative;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    speak: none;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: "entypo";
    font-size: 16px;
    font-size: 1.3em;
    margin-right: 10px;
    position: relative;
    top: 1px;
    margin-left: 3px; }

.address-full {
  margin-bottom: 0.80901rem; }
  .address-full:before {
    content: "";
    position: relative;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    speak: none;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: "entypo";
    font-size: 16px;
    font-size: 1.3em;
    margin-right: 5px;
    position: relative;
    top: 0;
    float: left;
    display: block; }
  .address-full .street-address-wrap,
  .address-full .city-postcode {
    padding-left: 25px; }

.facebook-link:before {
  content: "";
  position: relative;
  display: inline-block;
  width: auto;
  text-decoration: none;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  speak: none;
  text-transform: none;
  line-height: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-family: "entypo";
  font-size: 16px;
  font-size: 1.8em;
  margin-right: 7px;
  position: relative;
  top: 3px;
  color: #3b5998; }

.overlay .fl-heading span {
  background: rgba(255, 255, 255, 0.65);
  padding: 10px 15px; }

.overlay .fl-rich-text span {
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 15px; }

.thirds .fl-callout-photo-left .fl-callout-photo {
  width: 33.33333% !important; }

.thirds .fl-callout-photo-left .fl-callout-content {
  width: 66.66666% !important; }

/* Add your custom styles here... */
