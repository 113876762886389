/**
 * Icon Fonts
 */

.phone-number {
	margin-bottom: $small;

	@include icon('phone', 'before', true, 1.3em, $top:1px, $margin:6px) {
		margin-left: 2px;
	}
 }

 .mobile-number {
 	margin-bottom: $small;

	@include icon('mobile', 'before', true, 1.3em, $top:1px, $margin:10px) {
		 margin-left: 3px;
	 }
 }

 .email {
 	margin-bottom: $small;

	@include icon('mail', 'before', true, 1.3em, $top:1px, $margin:10px) {
		 margin-left: 3px;
	 }
 }

 .address-full {
 	margin-bottom: $small;

	@include icon('address', 'before', true, 1.3em, $top:0, $margin:5px) {
		float: left;
		display: block;
	}

	.street-address-wrap,
	.city-postcode {
		padding-left: 25px;
	}
 }

.facebook-link {
  @include icon('facebook-squared', 'before', true, 1.8em, $top:3px, $margin:7px) {
	  color: $facebook;
  }
}
