/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
    *zoom: 1;
}

@mixin clearfix {
	&:before,
	&:after {
		content: " "; /* 1 */
		display: table; /* 2 */
	}
	
	&:after {
		clear: both;
	}
	
	// For IE 6/7 only
	*zoom: 1;
}

/* ## Float Clearing
--------------------------------------------- */

.author-box:before,
.clearfix:before,
.entry:before,
.entry-content:before,
.footer-widgets:before,
.nav-primary:before,
.nav-secondary:before,
.pagination:before,
.site-container:before,
.site-footer:before,
.site-header:before,
.site-inner:before,
.wrap:before {
	content: " ";
	display: table;
}

.author-box:after,
.clearfix:after,
.entry:after,
.entry-content:after,
.footer-widgets:after,
.nav-primary:after,
.nav-secondary:after,
.pagination:after,
.site-container:after,
.site-footer:after,
.site-header:after,
.site-inner:after,
.wrap:after {
	clear: both;
	content: " ";
	display: table;
}

