// Sass integration for Entypo v2.0
//   https://github.com/rstacruz/sass_icon_fonts
//
// Website: http://www.entypo.com/
//
// Font files:
//   http://www.entypo.com/css/entypo.eot
//   http://www.entypo.com/css/entypo.svg
//   http://www.entypo.com/css/entypo.woff
//   http://www.entypo.com/css/entypo.ttf

$en-path: "entypo-plus" !default;

@mixin en-font {
  @font-face {
    font-family: "entypo";
    src: url("#{$en-path}.eot");
    src: url("#{$en-path}.eot?#iefix") format("embedded-opentype"), url("#{$en-path}.woff") format("woff"), url("#{$en-path}.ttf") format("truetype"), url("#{$en-path}.svg#entypo") format("svg");
    font-weight: normal;
    font-style: normal;
    speak: none;
  }
}

 
// List of unicode characters for entypo icons
$icons: (
   	'plus'				'\e816',
	'minus'				'\e819',
	'info'				'\e81e',
	'left-thin'			'\e88c',
	'up-thin'			'\e88e',
	'right-thin'		'\e88d',
	'down-thin'			'\e88b',
	'level-up'			'\e893',
	'level-down'		'\e892',
	'switch'			'\e896',
	'infinity'			'\e8c2',
	'plus-squared'		'\e818',
	'minus-squared'		'\e81b',
	'home'				'\e821',
	'keyboard'			'\e83a',
	'erase'				'\e8c3',
	'pause'				'\e899',
	'fast-forward'		'\e89d',
	'fast-backward'		'\e89e',
	'to-end'			'\e89b',
	'to-start'			'\e89c',
	'hourglass'			'\e863',
	'stop'				'\e898',
	'up-dir'			'\e886',
	'play'				'\e897',
	'right-dir'			'\e885',
	'down-dir'			'\e883',
	'left-dir'			'\e884',
	'adjust'			'\e867',
	'cloud'				'\e8b2',
	'star'				'\e808',
	'star-empty'		'\e809',
	'cup'				'\e846',
	'menu'				'\e811',
	'moon'				'\e8b5',
	'heart-empty'		'\e807',
	'heart'				'\e806',
	'note'				'\e800',
	'note-beamed'		'\e801',
	'layout'			'\e810',
	'flag'				'\e82a',
	'tools'				'\e856',
	'cog'				'\e855',
	'attention'			'\e83e',
	'flash'				'\e8b4',
	'record'			'\e89a',
	'cloud-thunder'		'\e8b3',
	'tape'				'\e8c8',
	'flight'			'\e8b6',
	'mail'				'\e805',
	'pencil'			'\e836',
	'feather'			'\e837',
	'check'				'\e812',
	'cancel'			'\e813',
	'cancel-circled'	'\e814',
	'cancel-squared'	'\e815',
	'help'				'\e81c',
	'quote'				'\e833',
	'plus-circled'		'\e817',
	'minus-circled'		'\e81a',
	'right'				'\e881',
	'direction'			'\e844',
	'forward'			'\e832',
	'ccw'				'\e88f',
	'cw'				'\e890',
	'left'				'\e880',
	'up'				'\e882',
	'down'				'\e87f',
	'list-add'			'\e8a6',
	'list'				'\e8a5',
	'left-bold'			'\e888',
	'right-bold'		'\e889',
	'up-bold'			'\e88a',
	'down-bold'			'\e887',
	'user-add'			'\e80c',
	'help-circled'		'\e81d',
	'info-circled'		'\e81f',
	'eye'				'\e826',
	'tag'				'\e827',
	'upload-cloud'		'\e82f',
	'reply'				'\e830',
	'reply-all'			'\e831',
	'code'				'\e834',
	'export'			'\e835',
	'print'				'\e838',
	'retweet'			'\e839',
	'comment'			'\e83b',
	'chat'				'\e83c',
	'vcard'				'\e840',
	'address'			'\e841',
	'location'			'\e842',
	'map'				'\e843',
	'compass'			'\e845',
	'trash'				'\e847',
	'doc'				'\e848',
	'doc-text-inv'		'\e84c',
	'docs'				'\e849',
	'doc-landscape'		'\e84a',
	'archive'			'\e851',
	'rss'				'\e853',
	'share'				'\e857',
	'basket'			'\e859',
	'shareable'			'\e858',
	'login'				'\e85c',
	'logout'			'\e85d',
	'volume'			'\e861',
	'resize-full'		'\e869',
	'resize-small'		'\e86a',
	'popup'				'\e86b',
	'publish'			'\e86c',
	'window:'			'\e86d',
	'arrow-combo'		'\e86e',
	'chart-pie'			'\e8c4',
	'language'			'\e8ca',
	'air'				'\e8ce',
	'database'			'\e8d3',
	'drive'				'\e8d4',
	'bucket'			'\e8d5',
	'thermometer'		'\e8d6',
	'down-circled'		'\e86f',
	'left-circled'		'\e870',
	'right-circled'		'\e871',
	'up-circled'		'\e872',
	'down-open'			'\e873',
	'left-open'			'\e875',
	'right-open'		'\e875',
	'up-open'			'\e876',
	'down-open-mini'	'\e877',
	'left-open-mini'	'\e878',
	'right-open-mini'	'\e879',
	'up-open-mini'		'\e87a',
	'down-open-big'		'\e87b',
	'left-open-big'		'\e87c',
	'right-open-big'	'\e87d',
	'up-open-big'		'\e87e',
	'progress-0'		'\e89f',
	'progress-1'		'\e8a0',
	'progress-2'		'\e8a1',
	'progress-3'		'\e8a2',
	'back-in-time'		'\e8aa',
	'network'			'\e8ad',
	'inbox'				'\e8af',
	'install'			'\e8b0',
	'lifebuoy'			'\e8b9',
	'mouse'				'\e8ba',
	'dot'				'\e8bd',
	'dot-2'				'\e8be',
	'dot-3'				'\e8bf',
	'suitcase'			'\e8bc',
	'flow-cascade'		'\e8d8',
	'flow-branch'		'\e8d9',
	'flow-tree'			'\e8da',
	'flow-line'			'\e8db',
	'flow-parallel'		'\e8dc',
	'brush'				'\e8c0',
	'paper-plane'		'\e8b7',
	'magnet'			'\e8c1',
	'gauge'				'\e8de',
	'traffic-cone'		'\e8df',
	'cc'				'\e8e0',
	'cc-by'				'\e8e1',
	'cc-nc'				'\e8e2',
	'cc-nc-eu'			'\e8e3',
	'cc-nc-jp'			'\e8e4',
	'cc-sa'				'\e8e5',
	'cc-nd'				'\e8e6',
	'cc-pd'				'\e8e7',
	'cc-zero'			'\e8e8',
	'cc-share'			'\e8e9',
	'cc-remix'			'\e8ea',
	'gplus-squared'		'\e920',
	'gplus'				'\e921',
	'angle-double-left'	'\e927',
	'angle-double-right''\e928',
	'angle-double-up'	'\e929',
	'angle-double-down'	'\e92a',
	'angle-circled-left''\e92b',
	'angle-circled-right''\e92c',
	'angle-circled-up'	'\e92d',
	'angle-circled-down''\e92e',
	'html5'				'\e924',
	'css3'				'\e922',
	'youtube-squared'	'\e91d',
	'youtube'			'\e91c',
	'flickr-squared'	'\e926',
	'bitbucket-squared'	'\e923',
	'github'			'\e8eb',
	'github-circled'	'\e8ec',
	'flickr'			'\e8ed',
	'twitter-squared'	'\e91e',
	'flickr-circled'	'\e8ee',
	'vimeo'				'\e8ef',
	'vimeo-circled'		'\e8f0',
	'twitter'			'\e8f1',
	'twitter-circled'	'\e8f2',
	'facebook'			'\e8f3',
	'facebook-circled'	'\e8f4',
	'facebook-squared'	'\e8f5',
	'linkedin-squared'	'\e91f',
	'gplus-circled'		'\e8f7',
	'pinterest'			'\e8f8',
	'pinterest-circled'	'\e8f9',
	'icon-tumblr'		'\e8fa',
	'tumblr-circled'	'\e8fb',
	'linkedin'			'\e8fc',
	'linkedin-circled'	'\e8fd',
	'dribbble'			'\e8fe',
	'dribbble-circled'	'\e8ff',
	'stumbleupon'		'\e900',
	'stumbleupon-circled''\e901',
	'lastfm'			'\e902',
	'lastfm-circled'	'\e903',
	'rdio'				'\e904',
	'rdio-circled'		'\e905',
	'spotify'			'\e906',
	'spotify-circled'	'\e907',
	'qq'				'\e908',
	'instagram'			'\e909',
	'dropbox'			'\e90a',
	'evernote'			'\e90b',
	'flattr'			'\e90c',
	'skype'				'\e90d',
	'skype-circled'		'\e90e',
	'renren'			'\e90f',
	'sina-weibo'		'\e910',
	'paypal'			'\e911',
	'picasa'			'\e912',
	'soundcloud'		'\e913',
	'mixi'				'\e914',
	'behance'			'\e915',
	'google-circles'	'\e916',
	'vkontakte'			'\e917',
	'smashing'			'\e918',
	'db-shape'			'\e91a',
	'sweden'			'\e919',
	'logo-db'			'\e91b',
	'picture'			'\e80e',
	'globe'				'\e8b1',
	'leaf'				'\e8b8',
	'graduation-cap'	'\e8c9',
	'mic'				'\e85e',
	'palette'			'\e8a4',
	'ticket'			'\e8cb',
	'video'				'\e80d',
	'target'			'\e8a3',
	'music'				'\e802',
	'trophy'			'\e8a8',
	'thumbs-up'			'\e82b',
	'thumbs-down'		'\e82c',
	'bag'				'\e85a',
	'user'				'\e80a',
	'users'				'\e80b',
	'lamp'				'\e864',
	'alert'				'\e83f',
	'water'				'\e8cc',
	'droplet'			'\e8cd',
	'credit-card'		'\e8cf',
	'monitor'			'\e8ab',
	'briefcase'			'\e8bb',
	'floppy'			'\e8d0',
	'cd'				'\e8ae',
	'folder'			'\e850',
	'doc-text'			'\e84b',
	'calendar'			'\e85b',
	'chart-line'		'\e8c5',
	'chart-bar'			'\e8c6',
	'clipboard'			'\e8d1',
	'attach'			'\e823',
	'bookmarks'			'\e829',
	'book'				'\e84f',
	'book-open'			'\e84e',
	'phone'				'\e854',
	'megaphone'			'\e8d2',
	'upload'			'\e82e',
	'download'			'\e82d',
	'box'				'\e852',
	'newspaper'			'\e84d',
	'mobile'			'\e8ac',
	'signal'			'\e8a7',
	'camera'			'\e80f',
	'shuffle'			'\e894',
	'loop'				'\e895',
	'arrows-ccw'		'\e891',
	'light-down'		'\e865',
	'light-up'			'\e866',
	'mute'				'\e85f',
	'sound'				'\e860',
	'battery'			'\e8a9',
	'search'			'\e803',
	'key'				'\e8d7',
	'lock'				'\e824',
	'lock-open'			'\e825',
	'bell'				'\e83d',
	'bookmark'			'\e828',
	'link'				'\e822',
	'back'				'\e820',
	'flashlight'		'\e804',
	'chart-area'		'\e8c7',
	'clock'				'\e862',
	'rocket'			'\e8dd',
	'block'				'\e868'
);

// Function to match name to unicode character
@function match($haystack, $needle) {
    @each $item in $haystack {
        $index: index($item, $needle);
        @if $index { 
            $return: if($index == 1, 2, $index);
            @return nth($item, $return); 
        }
    }
    @return false;
}

/*
* 	Main Icon mixin
*
* 	Based on http://jaydenseric.com/blog/fun-with-sass-and-font-icons 
*
*	e.g. 
*	@include icon('phone', 'before', true, $large, $top:-3px, $margin:5px);
* 	@include icon('phone', 'before', true, $large, $top:-3px, $margin:5px) {
*		color: black;
* 	}
*/
@mixin icon($icon: false, $position: 'before', $styles: true, $size: auto, $margin: auto, $color: auto, $top: auto, $shadow: none) {
    // Either a :before or :after pseudo-element, defaulting to :before
    &:#{$position} {
    	
    	
        @if $icon {
             // Icon has been specified
            content: match($icons, $icon);
        }
        
		@if $styles {
			// Supportive icon styles have been specified
			position: relative;
			display: inline-block;
			width: auto;
			text-decoration: none;
			//vertical-align: middle;
			text-rendering: optimizeLegibility !important;
			-webkit-font-smoothing: antialiased !important;
			-moz-osx-font-smoothing: grayscale !important;
			speak: none;
            text-transform: none;
            
            line-height: normal;
            font: {
                style: normal;
                variant: normal;
                weight: normal;
                family: "entypo";
                size: 16px;
                
                @if $size != auto {
					size: $size;
				}

            }
            
            @if $margin != auto {
            	@if $position == 'before' {
	            	margin-right: $margin;
            	} @else if $position == 'after'{
	            	margin-left: $margin;
            	}
			   
		    }
            
            @if $top != auto {
		      position: relative;
		      top: $top;
		    }
		    
		    @if $color != auto {
		      color: $color;
		    }
		    
		    @if $shadow != none {
		      text-shadow: $shadow;
		    }
            
            
        }
        // Include any extra rules supplied for the pseudo-element
        @content;
     
    }
}