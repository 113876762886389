.overlay {
  .fl-heading span {
    background: rgba($white, .65);
    padding: 10px 15px;
  }

  .fl-rich-text span {
    background: rgba($white, .8);
    padding: 10px 15px;
  }
}

.thirds {
  .fl-callout-photo-left .fl-callout-photo {
    // scss-lint:disable ImportantRule
    width: 33.33333% !important;
  }

  .fl-callout-photo-left .fl-callout-content {
    // scss-lint:disable ImportantRule
    width: 66.66666% !important;
  }
}
