.button-style {

   &:hover {

   }
}

.fl-builder .fl-builder-content {
   a.fl-button,
   a.fl-button:visited {
      @extend .button-style !optional;

      span {

      }
   }
}
.fl-page-content {
   button,
   input[type=button],
   input[type=submit] {
      @extend .button-style !optional;

   }
}

.fl-sidebar {
   .button-style {

      &:hover {

      }
   }
}

input#gform_submit_button_14.gform_button.button {
   @extend .button-style !optional;
}

.gform_wrapper input[type=email],
.gform_wrapper input[type=number],
.gform_wrapper input[type=password],
.gform_wrapper input[type=tel],
.gform_wrapper input[type=text],
.gform_wrapper input[type=url] {
   padding: 5px 8px !important;
   border-radius: 0 !important;
}
